import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="404: Not Found" />
        <h1>Schrödinger's webpage</h1>
        <p>At some point, this webpage was locked in the server directories with a flask of poison and a radioactive source. Prior to your discovery, this webpage was simultaneously active and inactive, but you have attempted to access the webpage and its superposition within this system has collapsed.</p>
        <p>In another world inconsistent with this one, the webpage may still exist and you may be enjoying a lovely, carefully-written paragraph about application monitoring or an image of people staring at a computer screen while smiling politely.</p>
        <p>Sorry we lead you astray. Check out the footer links to try accessing a different page to discover its superposition?</p>
      </Layout>
    )
  }
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
